import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/projects/22.png'
import pic02 from '../assets/images/projects/24.png'
import pic03 from '../assets/images/projects/23.png'
import pic04 from '../assets/images/projects/25.png'
import pic05 from '../assets/images/projects/3.png'
import pic06 from '../assets/images/projects/15.png'
import projects from '../assets/images/projects/projects.jpg'
import pic07 from '../assets/images/projects/98.jpg'
import pic08 from '../assets/images/projects/99.jpg'





// import mercedes from '../assets/images/logos/mercedes.png'
// import smart from '../assets/images/logos/smart.png'
// import eq from '../assets/images/logos/eq.png'
// import ey from '../assets/images/logos/EY.png'
// import keycrime from '../assets/images/logos/keycrime.png'
// import swat from '../assets/images/logos/swat.png'
// import quisque from '../assets/images/logos/quisque.png'
// import consilia from '../assets/images/logos/nhs.jpg'
// import makerland from '../assets/images/logos/makerland.png'
// import salone from '../assets/images/logos/salone.png'
// import qsis from '../assets/images/logos/museum.png'
// import museo from '../assets/images/logos/interparking.png'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Portfolio Sagar Satam"
          meta={[
            { name: 'Design', content: 'Portfolio' },
            { name: 'Design', content: 'product design, designer' },
          ]}
          bodyAttributes={{
            class: 'front',
          }}
        />

        <Banner />

        <div id="main">

        {/* <section id="one" className="title-block inner">
            <div className="hp-featured">
              <h1 className="hp-featured__title">
              What I do now  
              <br />
              & what I would like to do next   
              </h1>
            </div>
          </section>

          <br /> */}

          

        <div className="project-wrapper">
          <div className="project-content">
        <section className="process-section">
              
                <div className="changebg">

                <div className="process">
                <div className="process-left">
                  <h4>As a Service & UX/UI Designer</h4>
                {/* <p className="half-textlarge">
                
                I consider every aspect of the ecosystem;<br />
                from end-to-end and surface-to-core. 
                </p> */}

                <div className="image">
                <img src={pic07} alt="" />
                </div>

                <span className="credits-text">
                This includes Customers and their service experiences & interactions with Human, 
                 Physical and Digital touchpoints; and the Front & Back-office 
                 actors, their processes, tools, and systems. 
                 </span>
                 <br />
                 <span className="credits-text">
                 — mobile and web apps, internal platforms,
                 SaaS products, service experiences, and internal team SOPs and processes.
                 </span>
                 <br />
                 <span className="credits-text">
                 <a href="/aboutme">
                  <u>More about me, and things beyond work →</u>
                  </a>
                  </span>


                {/* <p>
                 This includes customers and their interactions with Human, 
                 Physical and Digital touchpoints, and the front and back-office 
                 actors, their processes, tools, and systems 
                 <br />
                 <br /> 
                 — mobile and web apps, 
                 SaaS products, service experiences, and internal team SOPs and processes.
                 <br />
                 <br /> 
                 <a href="/aboutme">
                  <u>more about me, and things beyond work →</u>
                  </a>
                 </p> */}
                 {/* This includes customers, the physical 
                 and digital touchpoints they interact with, 
                 and the front and back-office actors, 
                 their processes, tools, and systems. */}
                 
                 {/* My work spans designing for human interactions, mobile and web apps, 
                 SaaS products, service experiences, and internal team SOPs and processes.
                 <br />
                 <br />  */}

                

                </div>

                <div className="process-right">
                <h4>What I would like to continue doing</h4>
                {/* <p className="half-textlarge">
                
                Design AI-enabled experiences in balance with People, Profit, and Planet.
                </p> */}

                <div className="image">
                <img src={pic08} alt="" />
                </div>

                <span className="credits-text">
                I believe design can drive the creation of a healthier and a happier future. 
                 </span>
                 <br />
                <span className="credits-text">
                 I aim to use tech as an enabler to enhance the user experience, improve operational efficiency of service delivery teams and deliver business outcomes.
                 </span>
                 <br />
                 <span className="credits-text">
                 <a href="/what-offer">
                  <u>We don't need Apps! see my vision for AI-enabled experiences →</u>
                  </a>
                  </span>
                  
                  {/* <a href="/what-offer">
                  <u>more about my approach and outlook on AI →</u>
                  </a> */}
                </div>

                {/* <p>
                Here are five project stories from the HealthTech, 
                AgriTech, and Mobility domains that I am proud to 
                have positively contributed to.
                </p> */}

                </div>

                </div>

                {/* <section id="one" className="title-block inner">
                <br />
                </section> */}

                {/* <p className="hp-featured__note">
                Now that you know a bit about me, here are five project 
                stories from the HealthTech, AgriTech, and Mobility domains 
                where I am proud to have made positive contributions.
                </p> */}

                <img src={projects} alt="" />


                </section>

                </div>
                </div>

                
              {/* Here are five project stories from the HealthTech, 
                AgriTech, and Mobility domains that I am proud to 
                have positively contributed to. */}


          {/* <section id="one" className="title-block inner">
            <div className="hp-featured">
              <h1 className="hp-featured__title">
              What I do now  
              <br />
              & what I would like to do next
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </h1>

              <div className="hp-featured__content">

                <p className="hp-featured__largetext">
                 A Service & UX/UI Designer.
                 </p>
                 <p className="hp-featured__text">
                 I consider all aspects of the ecosystem — 
                 from end-to-end and surface-to-core. 
                 <br />
                 <br /> 
                 This includes customers, the physical 
                 and digital touchpoints they interact with, 
                 and the front and back-office actors, 
                 their processes, tools, and systems.
                 <br />
                 <br /> 
                 <a href="/aboutme">
                  <u>more about me, beyond work →</u>
                  </a>
                 </p>

                 <br />
                 <br /> 

                 <p className="hp-featured__largetext">
                 Designing AI-Enabled Experiences.
                 </p>
                 <p className="hp-featured__text">
                 We are entering a new era of services that promises to radically change the way people live, work, and interact.
                 <br />
                 <br /> 
                 I aim to use AI to enhance the user experience and improve the operational efficiency of service delivery teams.
                 <br />
                 <br /> 
                 <a href="/what-offer">
                  <u>more about my approach and outlook on AI →</u>
                  </a>
                </p>

                
              </div>
            </div>

            

          <p className="hp-featured__note">
          Here are five project stories from the HealthTech, 
          AgriTech, and Mobility domains that I am proud to 
          have positively contributed to.
          </p>

          </section> */}


          {/* <br /> */}

          
          

          <section id="two" className="tiles inner">
          {/* <p className="hp-featured__note">
                Now that you know a bit about me, here are five project 
                stories from the HealthTech, AgriTech, and Mobility domains 
                where I am proud to have made positive contributions.
                </p> */}
          {/* <br /> */}

            <div className="tiles__wrapper">
              <a href="/work/service-design-postop">
                <div className="tiles__content">
                  <div className="tiles__info">
                    <div className="tiles__number">01</div>

                    {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                    <div className="tiles__text">
                    Improved post surgery outcomes by enabling patients to 
                    manage their recovery independently with confidence through 
                    remote monitoring by clinical teams.
                    </div>
                    <br />
                    <p className="maisontags-card">AI-enabled, Research, UX Strategy, Service Design, User Experience, Digital UX/UI</p>
                    
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic01} alt="" />
                </div>
              </a>
            </div>

            <br />

            <div className="tiles__wrapper">
              {/* <a href="/work/service-design-muvisi-project03full2589"> */}
              <a href="/work/service-design-acrole">
                <div className="tiles__content">
                  <div className="tiles__info">
                    <div className="tiles__number">02</div>

                    {/* <h2 className="tiles__title">kin.</h2> */}

                    <div className="tiles__text">
                    {/* Redesigned the role of a field-ops personnel, Activation Coordinator,
                    to lower the cost to serve users, improve retention, and enhance quality 
                    of service delivery. */}

                    Redesigned the field-ops personnel
                     role by leveraging AI-enabled 
                     digital tools and optimizing offline & online 
                     processes to reduce costs and improve 
                     service quality.

                    {/* Prototyping and piloting multiple initiatives to re-design 
                    the role of a field-ops employee Activation Coordinator, AC
                    to reduce the cost to serve users, improve retention and quality 
                    of service delivery. */}
                    </div>
                    <br />
                    <p className="maisontags-card">AI-enabled, Research, UX & Business Strategy, Service Design, User Experience, Digital UX/UI</p>
                    {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>UX STRATEGY</li>
                        <li>USER EXPERIENCE</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic03} alt="" />
                </div>
              </a>
            </div>

            <br />

            <div className="tiles__wrapper">
              <a href="/work/service-design-mercedes">
                <div className="tiles__content">
                  <div className="tiles__info">
                    <div className="tiles__number">03</div>

                    {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                    <div className="tiles__text">
                    Lowered the barrier to electric car ownership by improving 
                    the accessibility and usability of the charging infrastructure 
                    for Mercedes-Benz and Smart car owners.

                    {/* Improving the accessibility and usability of the 
                    electric-car charging infrastructure for Mercedes 
                    Benz & Smart Electric Car owners. */}
                    </div>
                    <br />
                    <p className="maisontags-card">Research, Service Design, User Experience, Digital UX/UI</p>
                    {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>USER EXPERIENCE</li>
                        <li>DIGITAL UX/UI</li>
                        <li>INTERACTION DESIGN</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic05} alt="" />
                </div>
              </a>
            </div>

            <br />

            <div className="tiles__wrapper">
              <a href="/work/service-design-blueprint">
                <div className="tiles__content">
                  <div className="tiles__info">
                    <div className="tiles__number">04</div>

                    {/* <h2 className="tiles__title">tecne.</h2> */}

                    <div className="tiles__text">
                    Facilitated a Service Blueprint Workshop to provide holistic visibility 
                    across teams, leading to a pivot in the organization's 
                    strategy, business goals, and focus areas.
                    
                    {/* Facilitating a Service Blueprint Workshop to bring 
                    awareness and holistic visibility across all teams; 
                    leading to a pivot and redefinition of organisation's 
                    strategic direction, business goals and areas of focus. */}
                    </div>
                    <br />
                    <p className="maisontags-card">Facilitation, Research, Service Design, UX & Business Strategy</p>
                    {/* <div className="tiles__tags">
                      <ul>
                        <li>UX STRATEGY</li>
                        <li>INTERACTION DESIGN</li>
                        <li>DIGITAL UX/UI</li>
                        <li> PRODUCT DESIGN</li>
                        <li>BRANDING</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic02} alt="" />
                </div>
              </a>
            </div>

            

            <br />


            <div className="tiles__wrapper">
              <a href="/work/service-design-tecne">
                <div className="tiles__content">
                  <div className="tiles__info">
                    <div className="tiles__number">05</div>

                    {/* <h2 className="tiles__title">kin.</h2> */}

                    <div className="tiles__text">
                    Redesigned combat training with a sensor-fitted Underglove 
                    and an App to provide athletes and trainers with precise, 
                    data-informed feedback for improvement.
                    
                    
                    {/* Improving the credit recovery timelines, 
                    motivation and loyalty of users by designing 
                    a multi-component Progression System that 
                    engages users in a variety of ways. */}
                    </div>
                    <br />
                    <p className="maisontags-card">Research, UX Strategy, User Experience, Product Design, Digital UX/UI, Brand Identity</p>
                    {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>UX STRATEGY</li>
                        <li>USER EXPERIENCE</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic06} alt="" />
                </div>
              </a>
            </div>

            <br />


            <div className="tiles__wrapper">
              <a href="/work/service-design-progression">
                <div className="tiles__content">
                  <div className="tiles__info">
                    <div className="tiles__number">06</div>

                    {/* <h2 className="tiles__title">kin.</h2> */}

                    <div className="tiles__text">
                    Improved credit recovery timelines, user motivation, 
                    and loyalty by designing a comprehensive 'Progression 
                    System' with diverse engagement strategies.
                    
                    
                    {/* Improving the credit recovery timelines, 
                    motivation and loyalty of users by designing 
                    a multi-component Progression System that 
                    engages users in a variety of ways. */}
                    </div>
                    <br />
                    <p className="maisontags-card">Research, UX Strategy, User Experience, Digital UX/UI</p>
                    {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>UX STRATEGY</li>
                        <li>USER EXPERIENCE</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic04} alt="" />
                </div>
              </a>
            </div>


            

            

            {/* <div className="tiles__wrapper">
              <a href="/work/service-design-kin">
                <div className="tiles__content">
                  <div className="tiles__info">
                    <div className="tiles__number">04</div>

                    <h2 className="tiles__title">kin.</h2>

                    <div className="tiles__text">
                      Conversational-based service for Type II Diabetes
                      management that enables trusted relationships through
                      empathic encounters.
                    </div>
                    <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>UX STRATEGY</li>
                        <li>USER EXPERIENCE</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic04} alt="" />
                </div>
              </a>
            </div> */}

            <br />

            {/* <div className="tiles__wrapper">
              <a href="/work/service-design-bsmart">
                <div className="tiles__content">
                  <div className="tiles__info">
                    <div className="tiles__number">04</div>


                    <div className="tiles__text">
                      Unique experiences that bring people together over home
                      cooked meals hosted by local chefs in neighbourhoods.
                    </div>
                    <br />
                    <p className="maisontags-card">Service Design, User Experience, Digital UX/UI, Interaction design</p>

                    
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic03} alt="" />
                </div>
              </a>
            </div> */}

            
            
          </section>

          {/* <section id="clients" className="inner">
            <h2 className="client-title-text">
              some of the clients i have worked with.
            </h2>
            <ul>
              <li className="mercedes">
                <img src={mercedes} alt="" />
              </li>
              <li className="smart">
                <img src={smart} alt="" />
              </li>
              <li className="eq">
                <img src={eq} alt="" />
              </li>
              <li className="ey">
                <img src={ey} alt="" />
              </li>
              <li className="keycrime">
                <img src={keycrime} alt="" />
              </li>
              <li className="swat">
                <img src={swat} alt="" />
              </li>
              <li className="quisque">
                <img src={quisque} alt="" />
              </li>
              <li className="consilia">
                <img src={consilia} alt="" />
              </li>
              <li className="museo">
                <img src={museo} alt="" />
              </li>
              <li className="makerland">
                <img src={makerland} alt="" />
              </li>
              <li className="salone">
                <img src={salone} alt="" />
              </li>
              <li className="qsis">
                <img src={qsis} alt="" />
              </li>
            </ul>
          </section> */}

          {/* <section id="footertopcta" className="inner">
            <p className="text arrow-front">
              like what you see? <br />
              check out <a href="/work">all my work</a>
            </p>
          </section> */}

          <br />
          <section id="footertopcta" className="inner">
            <p className="text arrow-front">
            <a href="/what-offer">like what you see? know more about my approach to design</a>
            </p>
          </section>
          <br />

        </div>
      </Layout>
    )
  }
}

export default HomeIndex
