import React from 'react'
import landingsrlogo from '../assets/images/landing-srlogo.png'

const Banner = props => (
  <section id="banner" className="major">
    <div className="inner">


    
      <header className="major">

        <div className="content">
        <p className="hp-featured__note">
        I can’t do Salsa or Samba or Hip-Hop. But there is one dance that I’m good at 🔶🕺🏻
        </p>
        </div>

        <h1>
        DANCING BETWEEN <br />
        CURIOSITY AND <br /> 
        CRITICALITY
        </h1>

        <div className="content">
        <p className="hp-featured__note">
        <br />
        I design AI-enabled, data-informed, IoT experiences to create <span className="wavy">healthy & happy futures</span> 💚<br />
        From building an AgriTech product to uplift the livelihoods of smallholder farmers in rural Indonesia 🌽 <br />
        To designing Desirable and Viable IoT services for HealthTech, SportsTech, and Smart Mobility domains in Europe 🤖
        </p>
        </div>



        {/* <div className="content">
        <p className="hp-featured__note">
        My superpower? I craft
        </p>
        </div>

        <h1>
        AI ENABLED, <br />
        DATA INFORMED, <br /> 
        IOT EXPERIENCES.
        </h1>

        <div className="content">
        <p className="hp-featured__note">
        <br />
        I enjoy working on projects that create <span className="wavy">meaningful impact</span> 💚<br />
        From building an AgriTech product to uplift the livelihoods of smallholder farmers in rural Indonesia 🇮🇩 <br />
        To designing Desirable and Viable IoT services for HealthTech, SportsTech, and Smart Mobility domains in Europe 🇪🇺
        </p>
        </div> */}












 

        {/* <div id="srlandinglogo">
        <img src={landingsrlogo} alt=""/>
        </div> */}


        {/* <h1>
        Designing IoT <br />
        experiences for <br />
        Humans, Planet <br />
        & Businesses.
        </h1> */}

        {/* <h1>
        designing <br />
        IoT experiences
        </h1>



        <div className="content">
        <p>
        As a Service & UX/UI designer, I solve thorny problems with a <br /> 
        <span className="wavy">Human-Business-Planet</span> centric mindset.
        </p>
        </div> */}

        {/* <div className="content">
        <p>I enjoy working on <span className="wavy">purpose-driven projects</span> — 
        </p>
        </div> */}


        


        {/* I enjoy working on purpose-driven projects
        with a Human-Business-Planet 
        centric mindset. */}

        {/* Every experience is carefully 
        crafted in balance with People, 
        Business and Planet. */}

        {/* Crafting Experiences in Balance with People, Business, and Planet. */}

        {/* data-informed, AI-enabled IoT product & service experiences that benefit users and businesses. */}

        {/* Crafting every experience holistically
        in balance with People, 
        Business and Planet. */}

        {/* Designing holistic experiences
        with a Human-Business-Planet 
        centric mindset. */}

        {/* Designing holistic experiences <br /> 
        with a Human-Business-Planet <br /> 
        centric mindset. */}

        {/* — for customers & service delivery teams */}







        {/* <p>
        As a Service & UX/UI designer, I enjoy working on purpose-driven projects with a <br /> 
        <span className="wavy">Human-Business-Planet</span> centric mindset.
        </p> */}

        {/* <p className="hp-featured__note">
        I enjoy working on <span className="wavy">impact-focused</span> projects 🩵<br />
        From building AgriTech solutions to improve the livelihoods of smallholder farmers in rural Indonesia 🇮🇩 <br />
        to designing Data-informed, AI-enabled IoT products and services for the European market 🇪🇺
        </p> */}

        {/* <p>
        Designing <span className="wavy">holistic experiences</span> — 
        from building an AgriTech product for smallholder farmers in rural Indonesia; to AI-enabled IoT products 
        & services for the European market.
        </p> */}

        
      </header>
    </div>
  </section>

  
)

export default Banner
